import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { LoadingSpinner } from 'hds-react';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/loading-spinner/customisation",
  "title": "LoadingSpinner - Customisation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component.`}</p>
    <p>{`See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spinner-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spinner colour`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spinner-color-stage1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first spinner colour (when the `}<inlineCode parentName="td">{`multicolor`}</inlineCode>{` is set to true)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spinner-color-stage2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The second spinner colour (when the `}<inlineCode parentName="td">{`multicolor`}</inlineCode>{` is set to true)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spinner-color-stage3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The third spinner colour (when the `}<inlineCode parentName="td">{`multicolor`}</inlineCode>{` is set to true)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
<LoadingSpinner
  theme={{
    '--spinner-color': 'var(--color-tram)',
  }}
/>
<br />
<LoadingSpinner
  multicolor
  theme={{
    '--spinner-color-stage1': 'var(--color-coat-of-arms)',
    '--spinner-color-stage2': 'var(--color-tram)',
    '--spinner-color-stage3': 'var(--color-metro)'
  }}
/>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <div
    class="hds-loading-spinner"
    style="--spinner-color: var(--color-tram);"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
  <br />
  <div
    class="hds-loading-spinner hds-loading-spinner--multicolor"
    style="--spinner-color-stage1: var(--color-coat-of-arms); --spinner-color-stage2: var(--color-tram);
    --spinner-color-stage3: var(--color-metro);"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      